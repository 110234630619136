import { error, success, warning } from 'react-toastify-redux';
import _ from 'lodash';
import moment from 'moment';
import { parse } from 'json2csv';
import {
  ordersCollection,
  orderLogsCollection,
  usersCollection,
  firebase,
} from '../../firebase';
import { startLoading, stopLoading, redirect } from './ui';
import { getAdmin, getToken } from '../../utils/auth';
import { calculateTax } from '../../utils';
import { OrderStatus, PaymentStatus } from '../../constants/index';

export const OrderActionTypes = {
  GET_ORDERS: 'GET_ORDERS',
  GET_ORDER: 'GET_ORDER',
  GET_PAYMENT_LINK: 'GET_PAYMENT_LINK',
  GET_ORDER_LOG: 'GET_ORDER_LOG',
  CREATE_ORDER_LOG: 'CREATE_ORDER_LOG',
  GET_LAST_ORDER: 'GET_LAST_ORDER',
};

const apiEndpoint = `${process.env.REACT_APP_WOOSA_API_URI}/api/orders`;

/**
 * Retrieve multiple orders from firebase
 * @param {string} paging.orderBy order by field name
 * @param {string} paging.order get desc or asc
 * @param {number} paging.page current page
 * @param {number} paging.size per page size
 */
export const getOrders = ({ orderBy, order, page, size, filter, params }) => {
  return async (dispatch, getState) => {
    try {
      const {
        orders: { orders },
      } = getState();

      orderBy = orderBy || orders.paging.orderBy;
      order = order || orders.paging.order;
      page = page !== null ? page : orders.paging.page;
      size = size || orders.paging.size;
      params = params || orders.paging.params;

      // if nothing has changed, return
      if (
        orders.items.length - 1 >= page &&
        orders.paging.page === page &&
        orders.paging.order === order &&
        orders.paging.orderBy === orderBy &&
        orders.paging.size === size &&
        orders.paging.filter === filter &&
        orders.paging.params === params
      )
        return;

      let items = [...orders.items];
      let { lastSnapshot } = orders;
      const paging = { page, order, orderBy, size, filter, params };
      const {
        userId,
        status,
        productIndex,
        deliveryDateStatus,
        orderDeliveryStatus,
        allocationStatus,
        promoIndex,
      } = params;
      let { dateFrom, dateTo } = params;
      // if the page already has orders data just update the paging
      if (orders.items.length - 1 >= page && orders.paging.page !== page) {
        dispatch({
          type: OrderActionTypes.GET_ORDERS,
          orders: {
            items,
            paging,
            lastSnapshot,
          },
        });
        return;
      }

      dispatch(startLoading());

      let query = ordersCollection;

      if (dateFrom && dateTo) {
        dateFrom = moment(dateFrom).startOf('day').toDate();
        dateTo = moment(dateTo).endOf('day').toDate();
        query = query
          .where('orderedOn', '>=', dateFrom)
          .where('orderedOn', '<=', dateTo)
          .orderBy('orderedOn', 'desc');
      }

      query = query.orderBy(orderBy, order);

      if (status) query = query.where('status', '==', status);

      if (orderDeliveryStatus)
        query = query.where('deliveryStatus', '==', orderDeliveryStatus);

      if (deliveryDateStatus)
        query = query.where('deliveryDateStatus', '==', deliveryDateStatus);

      if (allocationStatus)
        query = query.where('allocationStockStatus', '==', allocationStatus);

      if (userId) query = query.where('userId', '==', userId);

      if (filter) {
        filter = filter.toLowerCase();
        query = query.where('indexes', 'array-contains-any', [filter]);
      } else if (productIndex)
        query = query.where('productIndexes', 'array-contains-any', [
          productIndex,
        ]);

      if (promoIndex) {
        query = query.where('promoIndexes', 'array-contains-any', [promoIndex]);
      }

      if (orders.paging.page !== page && orders.items.length - 1 < page) {
        query = query.startAfter(lastSnapshot);
      } else {
        items = [];
        paging.page = 0;
      }

      const documentSnapshots = await query.limit(size).get();
      let results = [];

      await Promise.all(
        documentSnapshots.docs.map(async (doc) => {
          const data = doc.data();
          const userDoc = await usersCollection.doc(data.userId).get();
          data.user = userDoc.data();
          results.push({ id: doc.id, ...data });
        })
      );

      lastSnapshot = documentSnapshots.docs[documentSnapshots.docs.length - 1];

      if (results.length > 0) {
        results = _.orderBy(results, 'refNum', 'desc');
        items.push(results);

        dispatch({
          type: OrderActionTypes.GET_ORDERS,
          orders: {
            items,
            paging,
            lastSnapshot,
          },
        });
      } else {
        dispatch(error('No orders found'));
      }
    } catch (err) {
      console.log('err', err.message);
      dispatch(error(err.message));
    } finally {
      dispatch(stopLoading());
    }
  };
};

/**
 * Export multiple orders from firebase
 * @param {string} paging.orderBy order by field name
 * @param {string} paging.order get desc or asc
 * @param {string} filter filter text
 * @param {object} params collection fields
 */
export const exportCsv = ({ orderBy, order, filter, params }) => {
  return async (dispatch, getState) => {
    try {
      const {
        orders: { orders },
      } = getState();

      orderBy = orderBy || orders.paging.orderBy;
      order = order || orders.paging.order;
      params = params || orders.paging.params;

      // if nothing has changed, return
      if (
        orders.paging.order === order &&
        orders.paging.orderBy === orderBy &&
        orders.paging.filter === filter &&
        orders.paging.params === params
      )
        return;

      const { userId, status, productIndex, promoIndex } = params;
      let { dateFrom, dateTo } = params;

      dispatch(startLoading());

      let query = ordersCollection;

      if (dateFrom && dateTo) {
        dateFrom = moment(dateFrom).startOf('day').toDate();
        dateTo = moment(dateTo).endOf('day').toDate();
        query = query
          .where('orderedOn', '>=', dateFrom)
          .where('orderedOn', '<=', dateTo)
          .orderBy('orderedOn', 'desc');
      }

      query = query.orderBy(orderBy, order);

      if (status) query = query.where('status', '==', status);

      if (userId) query = query.where('userId', '==', userId);

      if (filter) {
        filter = filter.toLowerCase();
        query = query.where('indexes', 'array-contains-any', [filter]);
      } else if (productIndex)
        query = query.where('productIndexes', 'array-contains-any', [
          productIndex,
        ]);

      if (promoIndex) {
        query = query.where('promoIndexes', 'array-contains-any', [promoIndex]);
      }

      const documentSnapshots = await query.get();
      let results = [];

      await Promise.all(
        documentSnapshots.docs.map(async (doc) => {
          const data = doc.data();
          const userDoc = await usersCollection.doc(data.userId).get();
          const userData = userDoc.data();
          data.user = userData;
          results.push({ id: doc.id, ...data });
        })
      );
      if (!results.length) {
        dispatch(warning('No orders found!'));
        return;
      }
      results = _.orderBy(results, 'refNum', 'desc');

      const csvData = results.map((data) => {
        let paymentMethod = '';
        let shippingDetails = '';
        let billingDetails = '';

        data.orderedOn = moment
          .unix(data.orderedOn.seconds)
          .format('DD/MM/YYYY');

        if (data.payments) {
          paymentMethod = data.payments
            .filter((p) => p.status === PaymentStatus.SUCCESS)
            .map(
              (p) =>
                `${p.type}: ${moment
                  .unix(p.paidOn.seconds)
                  .format('DD/MM/YYYY')}`
            )
            .join('\r\n');
        }

        const productList = data.products
          .map((p) => `Qty:${p.qty}  ${p.collection}--${p.variant}`)
          .join('\r\n');

        shippingDetails = `${data.shipping.firstName} ${data.shipping.lastName}\r\n`;
        shippingDetails += `${data.shipping.contactNo}\r\n`;
        shippingDetails += `${data.shipping.address}\r\n`;
        shippingDetails += `${data.shipping.country}\r\n`;

        billingDetails = `${data.billing.firstName} ${data.billing.lastName}\r\n`;
        billingDetails += `${data.billing.contactNo}\r\n`;
        billingDetails += `${data.billing.address}\r\n`;
        billingDetails += `${data.billing.country}\r\n`;

        const gstAmount = data.tax || calculateTax(data.finalPrice);

        return {
          csv_refNum: data.refNum,
          csv_orderedOn: data.orderedOn,
          csv_status: data.status,
          csv_customer: data.user
            ? `${data.user.firstName} ${data.user.lastName}`
            : '',
          csv_email: data.user ? data.user.email : '',
          csv_createdBy: data.audit ? data.audit.createdBy : '',
          csv_totalPrice: data.totalPrice.toFixed(2),
          csv_discount: data.discount.toFixed(2),
          csv_shippingFee: data.shippingFee ? data.shippingFee.toFixed(2) : '0',
          csv_priceBeforeGst: (data.finalPrice - gstAmount.amount).toFixed(2),
          csv_GstAmount: gstAmount.amount.toFixed(2),
          csv_finalPrice: data.finalPrice.toFixed(2),
          csv_paymentMethod: paymentMethod,
          csv_productList: productList,
          csv_shippingDetails: shippingDetails,
          csv_billingDetails: billingDetails,
        };
      });

      const fields = Object.keys(csvData[0]);
      const opts = { fields };
      let csv = parse(csvData, opts);
      csv = csv
        .replace('csv_refNum', 'Ref No')
        .replace('csv_orderedOn', 'Order Date')
        .replace('csv_status', 'Status')
        .replace('csv_customer', 'Customer')
        .replace('csv_email', 'Email')
        .replace('csv_createdBy', 'Created By')
        .replace('csv_totalPrice', 'Total Price')
        .replace('csv_discount', 'Discount')
        .replace('csv_shippingFee', 'Shipping Fee')
        .replace('csv_priceBeforeGst', 'Price before GST')
        .replace('csv_GstAmount', 'GST Amount')
        .replace('csv_finalPrice', 'Final Price')
        .replace('csv_paymentMethod', 'Payment Method')
        .replace('csv_productList', 'Products')
        .replace('csv_shippingDetails', 'Shipping Details')
        .replace('csv_billingDetails', 'Billing Details');
      const downloadLink = document.createElement('a');
      const blob = new Blob(['\ufeff', csv]);
      const url = URL.createObjectURL(blob);
      downloadLink.href = url;
      downloadLink.download = 'data.csv';
      downloadLink.click();
      downloadLink.remove();
    } catch (err) {
      dispatch(error(err.message));
    } finally {
      dispatch(stopLoading());
    }
  };
};

/**
 * Retrieve single order from firebase
 * @param {string} id get by object id
 */
export const getOrder = (id) => {
  return async (dispatch) => {
    try {
      dispatch(startLoading());

      const doc = await ordersCollection.doc(id).get();

      const order = {
        id: doc.id,
        ...doc.data(),
      };

      if (!order.shippingFee) order.shippingFee = 0;

      const userDoc = await usersCollection.doc(order.userId).get();
      order.user = userDoc.data();

      dispatch({
        type: OrderActionTypes.GET_ORDER,
        order,
      });
    } catch (err) {
      dispatch(error(err.message));
    } finally {
      dispatch(stopLoading());
    }
  };
};

/**
 * Retrieve single order from firebase
 * @param {string} userId get by object id
 */
export const getLastOrder = (userId) => {
  return async (dispatch) => {
    try {
      dispatch(startLoading());

      const documentSnapshots = await ordersCollection
        .where('userId', '==', userId)
        .where('status', 'in', [OrderStatus.COMPLETED, OrderStatus.PROCESSING])
        .orderBy('orderedOn', 'desc')
        .limit(1)
        .get();

      if (documentSnapshots.docs.length === 0) return;

      const doc = documentSnapshots.docs[0];

      const lastOrder = {
        id: doc.id,
        ...doc.data(),
      };

      const userDoc = await usersCollection.doc(lastOrder.userId).get();
      lastOrder.user = userDoc.data();

      dispatch({
        type: OrderActionTypes.GET_LAST_ORDER,
        lastOrder,
      });
    } catch (err) {
      dispatch(error(err.message));
    } finally {
      dispatch(stopLoading());
    }
  };
};

/**
 * Get payment link from api
 * @param {string} id get by object id
 */
export const getPaymentLink = (id) => {
  return async (dispatch) => {
    try {
      dispatch(startLoading());

      const response = await fetch(`${apiEndpoint}/payment_link/${id}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${getToken().token}`,
        },
        body: JSON.stringify({
          env: process.env.REACT_APP_ENV,
        }),
      });
      if (response.status !== 201 && response.status !== 200) throw response;
      const data = await response.json();

      dispatch({
        type: OrderActionTypes.GET_PAYMENT_LINK,
        paymentLink: data.paymentLink,
      });
    } catch (err) {
      dispatch(error('Unexpected error!'));
    } finally {
      dispatch(stopLoading());
    }
  };
};

/**
 * Retrieve single order from firebase
 * @param {string} id get by object id
 */
export const getOrderLog = (id) => {
  return async (dispatch) => {
    try {
      dispatch(startLoading());

      const doc = await orderLogsCollection.doc(id).get();
      const data = doc.data();
      const orderLog = {
        id: doc.id,
        logs: data ? data.logs : [],
      };
      orderLog.logs = _.orderBy(orderLog.logs, 'createdOn', 'desc');

      dispatch({
        type: OrderActionTypes.GET_ORDER_LOG,
        orderLog,
      });
    } catch (err) {
      dispatch(error(err.message));
    } finally {
      dispatch(stopLoading());
    }
  };
};

/**
 * Retrieve single order from firebase
 * @param {string} id
 * @param {object} obj
 */
export const createOrderLog = (id, obj) => {
  return async (dispatch) => {
    try {
      dispatch(startLoading());

      const doc = await orderLogsCollection.doc(id).get();
      let data = doc.data();
      if (data) {
        if (!data.logs) data.logs = [];
        data.logs.push(obj);
      } else {
        data = {
          logs: [obj],
        };
      }
      await orderLogsCollection.doc(id).set(data);

      dispatch(getOrderLog(id));
    } catch (err) {
      dispatch(error(err.message));
    } finally {
      dispatch(stopLoading());
    }
  };
};

/**
 * delete a log from OrderLog
 * @param {string} id
 * @param {number} uuid
 */
export const deleteOrderLog = (id, uuid) => {
  return async (dispatch) => {
    try {
      dispatch(startLoading());

      const doc = await orderLogsCollection.doc(id).get();
      const data = doc.data();
      data.logs = data.logs.filter((l) => l.uuid !== uuid);
      await orderLogsCollection.doc(id).update(data);

      dispatch(getOrderLog(id));
    } catch (err) {
      dispatch(error(err.message));
    } finally {
      dispatch(stopLoading());
    }
  };
};

/**
 * send invoice to customer's email
 * @param {string} id
 * @param {boolean} isUpdated
 */
export const sendInvoice = (id, isUpdated) => {
  return async (dispatch) => {
    try {
      dispatch(startLoading());

      const response = await fetch(`${apiEndpoint}/invoice/${id}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${getToken().token}`,
        },
        body: JSON.stringify({
          env: process.env.REACT_APP_ENV,
          isUpdated,
        }),
      });
      if (response.status !== 201 && response.status !== 200) throw response;
      dispatch(success('Invoice has been successfully sent!'));
    } catch (err) {
      dispatch(error('Unexpected error!'));
    } finally {
      dispatch(stopLoading());
    }
  };
};

/**
 * download invoice
 * @param {string} id
 */
export const downloadInvoice = (id) => {
  return async (dispatch) => {
    try {
      dispatch(startLoading());

      const response = await fetch(`${apiEndpoint}/invoice/pdf/${id}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${getToken().token}`,
        },
      });
      if (response.status !== 201 && response.status !== 200) throw response;

      const data = await response.text();
      const downloadLink = document.createElement('a');
      downloadLink.href = data;
      downloadLink.download = 'invoice.pdf';
      downloadLink.click();
      downloadLink.remove();
    } catch (err) {
      dispatch(error('Unexpected error!'));
    } finally {
      dispatch(stopLoading());
    }
  };
};

/**
 * download invoice by version
 * @param {string} id
 * @param {number} version
 */
export const downloadInvoiceVersion = (id, version) => {
  return async (dispatch) => {
    try {
      dispatch(startLoading());

      const response = await fetch(
        `${apiEndpoint}/invoice/pdf/${id}/${version}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getToken().token}`,
          },
        }
      );
      if (response.status !== 201 && response.status !== 200) throw response;

      const data = await response.text();
      const downloadLink = document.createElement('a');
      downloadLink.href = data;
      downloadLink.download = 'invoice.pdf';
      downloadLink.click();
      downloadLink.remove();
    } catch (err) {
      dispatch(error('Unexpected error!'));
    } finally {
      dispatch(stopLoading());
    }
  };
};

/**
 * download do
 * @param {string} id
 * @param {number} unix
 */
export const downloadDO = (id, unix) => {
  return async (dispatch) => {
    try {
      dispatch(startLoading());

      const response = await fetch(
        `${apiEndpoint}/delivery_order/pdf/${id}/${unix}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getToken().token}`,
          },
        }
      );
      if (response.status !== 201 && response.status !== 200) throw response;

      const data = await response.text();
      const downloadLink = document.createElement('a');
      downloadLink.href = data;
      downloadLink.download = 'DO.pdf';
      downloadLink.click();
      downloadLink.remove();
    } catch (err) {
      dispatch(error('Unexpected error!'));
    } finally {
      dispatch(stopLoading());
    }
  };
};

/**
 * send do to customer
 * @param {string} id
 * @param {number} unix
 */
export const sendDO = (id, unix) => {
  return async (dispatch) => {
    try {
      dispatch(startLoading());
      const response = await fetch(
        `${apiEndpoint}/delivery_order/${id}/${unix}`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${getToken().token}`,
          },
          body: JSON.stringify({ env: process.env.REACT_APP_ENV }),
        }
      );
      if (response.status !== 201 && response.status !== 200) throw response;

      const result = await response.json();

      dispatch(success(result.message || 'Delivery order has been sent out!'));
    } catch (err) {
      dispatch(error('Unexpected error!'));
    } finally {
      dispatch(stopLoading());
    }
  };
};

/**
 * send payment link to customer's email
 * @param {string} id
 * @param {boolean} isUpdated
 */
export const sendPaymentLink = (id, isUpdated) => {
  return async (dispatch) => {
    try {
      dispatch(startLoading());

      const response = await fetch(`${apiEndpoint}/invoice/payment/${id}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${getToken().token}`,
        },
        body: JSON.stringify({ env: process.env.REACT_APP_ENV, isUpdated }),
      });
      if (response.status !== 201 && response.status !== 200) throw response;

      dispatch(success('Payment link has been sent out!'));
    } catch (err) {
      const errRes = await err.json();
      dispatch(error(errRes.message || 'Unexpected error!'));
    } finally {
      dispatch(stopLoading());
    }
  };
};

/**
 * send delivery schedule link
 * @param {string} id
 */
export const sendDeliveryScheduleLink = (id) => {
  return async (dispatch) => {
    try {
      dispatch(startLoading());

      const response = await fetch(`${apiEndpoint}/delivery/schedule/${id}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${getToken().token}`,
        },
        body: JSON.stringify({
          env: process.env.REACT_APP_ENV,
        }),
      });
      if (response.status !== 201 && response.status !== 200) throw response;
      dispatch(success('Delivery schedule link has been successfully sent!'));
    } catch (err) {
      const errRes = await err.json();
      dispatch(error(errRes.message || 'Unexpected error!'));
    } finally {
      dispatch(stopLoading());
    }
  };
};

/**
 * Update order status
 * @param {string} id
 * @param {enum} status
 */
export const updateOrderStatus = (id, status) => {
  return async (dispatch) => {
    try {
      dispatch(startLoading());

      const orderRef = await ordersCollection.doc(id).get();
      const order = orderRef.data();

      // audit
      order.audit = {
        ...order.audit,
        modifiedBy: getAdmin().name,
        modifiedOn: firebase.firestore.FieldValue.serverTimestamp(),
      };

      order.status = status;

      await ordersCollection.doc(id).set(order);
      dispatch(redirect(`/orders/edit/${id}`));
      setTimeout(() => dispatch(success('Successfully updated!')), 200);
      setTimeout(() => window.location.reload(), 500);
    } catch (err) {
      dispatch(error(err.message));
    } finally {
      dispatch(stopLoading());
    }
  };
};

/**
 * Create order
 * @param {object} Order
 * @param {Array[string]} promoCodes
 */
export const createOrder = (order, promoCodes) => {
  return async (dispatch) => {
    try {
      dispatch(startLoading());

      if (!order.products.length) return;

      const response = await fetch(`${apiEndpoint}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${getToken().token}`,
        },
        body: JSON.stringify({ order, promoCodes }),
      });
      if (response.status !== 201 && response.status !== 200) throw response;
      const result = await response.json();

      if (result.order) {
        dispatch(redirect(`/orders/edit/${result.order.id}`));
        setTimeout(() => dispatch(success('Successfully created!')), 200);
      } else dispatch(error('Unexpected error!'));
    } catch (err) {
      const errJson = await err.json();
      dispatch(error(errJson.message || 'Unexpected error!'));
    } finally {
      dispatch(stopLoading());
    }
  };
};

/**
 * Update order
 * @param {string} id
 * @param {object} Order
 */
export const updateOrder = (id, order) => {
  return async (dispatch) => {
    try {
      dispatch(startLoading());

      if (!order.products.length) return;

      const response = await fetch(`${apiEndpoint}/${id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${getToken().token}`,
        },
        body: JSON.stringify({ order }),
      });
      if (response.status !== 201 && response.status !== 200) throw response;
      const result = await response.json();

      if (result.order) {
        dispatch(success('Successfully updated!'));
        setTimeout(() => window.location.reload(), 500);
      } else dispatch(error('Unexpected error!'));
    } catch (err) {
      const errJson = await err.json();
      dispatch(error(errJson.message || 'Unexpected error!'));
    } finally {
      dispatch(stopLoading());
    }
  };
};
