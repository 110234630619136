import React, { useEffect, useState } from 'react';
import { Row, Col, Button } from 'shards-react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import moment from 'moment';
import {
  SmartTable,
  SearchCard,
  FormGroup,
  PaginationButton,
} from '../../components/common';
import {
  getDeliveryOrder,
  exportCsv,
} from '../../store/actions/deliveryOrders';
import { getOrderFilters } from '../../store/actions/orderFilters';
import { MainStage } from '../../components/layout';

const DeliveryScheduleListing = () => {
  const { control } = useForm();
  const [orders, setOrders] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [productOptions, setProductOptions] = useState([]);
  const [variantOptions, setVariantOptions] = useState([]);
  const [productFilter, setProductFilter] = useState('');
  const [variantFilter, setVariantFilter] = useState('');
  const [dateFrom, setDateFrom] = useState(moment().toDate());
  const [dateTo, setDateTo] = useState(moment().add(1, 'month').toDate());
  const dispatch = useDispatch();
  const orderFiltersData = useSelector(
    ({ orderFilters: { orderFilters } }) => orderFilters
  );
  const { items, paging } = useSelector(
    ({ deliveryOrders: { deliveryOrders } }) => deliveryOrders
  );

  const columns = [
    {
      header: 'Order Ref',
      accessor: 'refNum',
      cell: (row, data) => (
        <Link to={`/orders/view/${data.orderId}`}>{row.value}</Link>
      ),
    },
    {
      header: 'Order Products',
      accessor: 'products',
      cell: (row, data) => (
        <div>
          {data.products.map((p, pIndex) => {
            return (
              <div
                key={pIndex}
                className={pIndex < data.products.length - 1 ? 'mb-2' : ''}
              >
                <span className="mr-2">Qty: {p.qty}</span>
                {p.name}--{p.variant}
              </div>
            );
          })}
        </div>
      ),
    },
    {
      header: 'Delivery Date',
      accessor: 'deliveryDate',
      cell: (row) => (
        <span>{moment.unix(row.value.seconds).format('D MMM YYYY')}</span>
      ),
    },
    {
      header: 'Delivery Time',
      accessor: 'deliveryTime',
    },
  ];

  useEffect(() => {
    if (items.length) {
      console.log('items', items);
      setOrders(items.slice(0, paging.size));
    }
  }, [items]);

  useEffect(() => {
    dispatch(getOrderFilters());
  }, []);

  useEffect(() => {
    const options = [];
    if (orderFiltersData.items.length) {
      const products = orderFiltersData.items;
      products.forEach((p) => {
        options.push({ value: p.id, label: p.name });
      });
    }
    setProductOptions(options);
  }, [orderFiltersData]);

  const handleProductOnChange = (value) => {
    setProductFilter(value);
    if (!value) {
      setVariantOptions([]);
      return;
    }
    const selectedProduct = orderFiltersData.items.find((i) => i.id === value);
    const options = [];
    if (selectedProduct.variants.length) {
      selectedProduct.variants.forEach((v) => {
        options.push({ value: v, label: v });
      });
    }
    setVariantOptions(options);
  };

  const handleVariantOnChange = (value) => {
    setVariantFilter(value);
  };

  const handleSearchDeliveryOrders = () => {
    const params = {
      productId: productFilter,
      variant: variantFilter,
      dateFrom,
      dateTo,
    };
    setCurrentPage(1);
    dispatch(getDeliveryOrder({ params }));
  };

  const handlePageChanged = (page) => {
    setCurrentPage(page);
    setOrders(items.slice((page - 1) * paging.size, paging.size * page));
  };

  const renderPaging = () => {
    if (!paging) return;
    return (
      <PaginationButton
        totalPages={paging.totalPages}
        currentPage={currentPage}
        onClick={handlePageChanged}
      />
    );
  };

  const handleExportCsv = () => {
    if (!items.length) return;
    dispatch(exportCsv(items));
  };

  return (
    <MainStage title="Overview" subtitle="Delivery Orders">
      <Row>
        <Col lg="12">
          <SearchCard title="Search delivery orders">
            <Row>
              <FormGroup
                col={{ lg: '3' }}
                type="datepicker"
                name="dateFrom"
                label="Delivery Date From"
                control={control}
                defaultValue={dateFrom}
                onChange={(selected) => setDateFrom(selected)}
                dateProps={{ maxDate: dateTo }}
              />
              <FormGroup
                col={{ lg: '3' }}
                type="datepicker"
                name="dateTo"
                label="Delivery Date To"
                control={control}
                defaultValue={dateTo}
                onChange={(selected) => setDateTo(selected)}
                dateProps={{ minDate: dateFrom }}
              />
              {productOptions.length > 0 && (
                <FormGroup
                  col={{ lg: '3' }}
                  type="select"
                  options={productOptions}
                  name="product"
                  label="Product"
                  control={control}
                  onChange={(e) => handleProductOnChange(e.target.value)}
                />
              )}
              {variantOptions.length > 0 && (
                <FormGroup
                  col={{ lg: '3' }}
                  type="select"
                  options={variantOptions}
                  name="variant"
                  label="Variant"
                  control={control}
                  onChange={(e) => handleVariantOnChange(e.target.value)}
                />
              )}
            </Row>
            <div className="d-flex">
              <Button
                type="button"
                theme="success"
                onClick={handleSearchDeliveryOrders}
              >
                Search
              </Button>
              <Button
                className="ml-2"
                type="button"
                theme="primary"
                onClick={handleExportCsv}
              >
                Export
              </Button>
            </div>
          </SearchCard>
        </Col>
        <Col>
          <SmartTable title="Delivery Orders" data={orders} columns={columns} />
          {renderPaging()}
        </Col>
      </Row>
    </MainStage>
  );
};

export default DeliveryScheduleListing;
