import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import {
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  Badge,
  Row,
  Col,
  Button,
} from 'shards-react';
import styled from 'styled-components';

import {
  OrderStatus,
  StatusColors,
  PromoTypes,
  DiscountAppliesTo,
  DiscountTypes,
  PaymentTypes,
} from '../../constants';
import PromoBadge from './PromoBadge';
import TooltipBadge from './TooltipBadge';
import MaterialIcon from './MaterialIcon';
import StripePaymentInfo from './StripePaymentInfo';
import AtomePaymentInfo from './AtomePaymentInfo';
import { calculateTax } from '../../utils';

const ProductListContainer = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
`;

const ProductImage = styled.img`
  border-radius: 0.625rem;
  max-width: 150px;
`;

const ProductList = styled.li`
  display: flex;
  align-items: center;
`;

const OrderDetailCard = ({ order, isPreview }) => {
  const isEditable = () => {
    if (
      order.status !== OrderStatus.CANCELLED &&
      order.status !== OrderStatus.REFUNDED &&
      order.status !== OrderStatus.COMPLETED
    )
      return true;
    return false;
  };

  const formatPrice = (price) => {
    if (price && price > 0) return `${order.currency} ${price.toFixed(2)}`;
    return `${order.currency} 0.00`;
  };

  const hasValidProductCompareUnitPrice = (product) => {
    return (
      product.comparePrice &&
      product.comparePrice > 0 &&
      product.comparePrice > product.unitPrice
    );
  };

  const showProductPrice = (product) => {
    if (hasValidProductCompareUnitPrice(product)) {
      return (
        <>
          <span>{formatPrice(product.unitPrice)}</span>
          <strike className="d-inline-block text-danger ml-2">
            {formatPrice(product.comparePrice)}
          </strike>
        </>
      );
    }
    return formatPrice(product.unitPrice);
  };

  const showProductSubtotal = (product, promos) => {
    let totalDiscount = 0;
    if (promos.length) {
      totalDiscount = promos
        .filter(
          (promo) =>
            promo.type === PromoTypes.DISCOUNT &&
            promo.type in promo &&
            promo[promo.type].appliesTo === DiscountAppliesTo.PRODUCTS
        )
        .reduce((acc, promo) => {
          const { type, value, oncePerOrder } = promo[promo.type];

          if (type === DiscountTypes.FIXED) {
            acc += oncePerOrder ? value : value * product.qty;
          } else {
            acc +=
              (oncePerOrder ? product.unitPrice : product.subtotal) *
              (value / 100);
          }
          return acc;
        }, 0);
    } else {
      product.subtotal = product.unitPrice * product.qty;
    }

    const productSubtotal = formatPrice(product.subtotal);

    if (totalDiscount > 0) {
      return (
        <>
          <strike className="text-danger">{productSubtotal}</strike> <br />
          <span>{formatPrice(product.subtotal - totalDiscount)}</span>
        </>
      );
    }
    return productSubtotal;
  };

  const getProductPromos = (productIndex) => {
    const { promos } = order;
    if (!promos) return [];
    const linkedPromos = promos.filter(
      (promo) => promo.refs && productIndex in promo.refs
    );
    return linkedPromos;
  };

  const getEntireOrderPromos = () => {
    const { promos } = order;
    const entireOrderPromos = promos;
    // ? promos.filter((promo) => promo.amount > 0)
    // : [];

    if (entireOrderPromos && entireOrderPromos.length > 0) {
      return (
        <div>
          {entireOrderPromos.map((promo, i) => (
            <PromoBadge promo={promo} key={i} className="mr-2" />
          ))}
        </div>
      );
    }

    return null;
  };

  const showAddressInfo = (info) => {
    const {
      firstName,
      lastName,
      email,
      address,
      company,
      contactNo,
      country,
      postal,
    } = info;

    return (
      <>
        <MaterialIcon className="mr-2" icon="person" />
        {firstName} {lastName} <br />
        {company && (
          <>
            {company} <br />
          </>
        )}
        <MaterialIcon className="mr-2" icon="email" />
        <a href={`mailto:${email}`} target="_blank" rel="noopener noreferrer">
          {email}
        </a>{' '}
        <br />
        <MaterialIcon className="mr-2" icon="phone" />
        <a href={`tel:${contactNo}`} target="_blank" rel="noopener noreferrer">
          {contactNo}
        </a>{' '}
        <br />
        <MaterialIcon className="mr-2" icon="home" />
        {address} <br />
        {country} {postal} <br />
      </>
    );
  };

  const showUserInfo = (info) => {
    const {
      address,
      firstName,
      lastName,
      email,
      company,
      contactNo,
      country,
      postal,
    } = info;

    return (
      <>
        <MaterialIcon className="mr-2" icon="person" />
        {firstName} {lastName} <br />
        {company && (
          <>
            {company} <br />
          </>
        )}
        <MaterialIcon className="mr-2" icon="email" />
        <a href={`mailto:${email}`} target="_blank" rel="noopener noreferrer">
          {email}
        </a>{' '}
        <br />
        <MaterialIcon className="mr-2" icon="phone" />
        <a href={`tel:${contactNo}`} target="_blank" rel="noopener noreferrer">
          {contactNo}
        </a>{' '}
        <br />
        <MaterialIcon className="mr-2" icon="home" />
        {address} <br />
        {country} {postal} <br />
      </>
    );
  };

  const showReferralInfo = () => {
    const { referral } = order;
    if (!referral) return null;
    const { referee } = referral;
    if (referral && referral.code) {
      return (
        <div>
          <TooltipBadge id="referral-info" badge={order.referral.code}>
            Customer gets ${referee.amount.toFixed(2)} by using this referral
            code.
          </TooltipBadge>
        </div>
      );
    }

    return null;
  };

  const renderPaymentInfo = (payment) => {
    switch (payment.type) {
      case PaymentTypes.ATOME:
        return <AtomePaymentInfo payment={payment} separator="space" />;
      default:
        return <StripePaymentInfo payment={payment} separator="space" />;
    }
  };

  const renderTaxInfo = () => {
    const tax = order.tax || calculateTax(order.finalPrice);
    return (
      <tr>
        <td>
          <small>GST ({(tax.rate * 100).toFixed(0)}%) Inclusive:</small>
        </td>
        <td className="text-right">
          <small>{formatPrice(tax.amount)}</small>
        </td>
      </tr>
    );
  };

  return (
    <Card small>
      <CardHeader className="border-bottom">
        <Row>
          <Col className="d-flex align-items-center">
            <h5 className="m-0">
              <Link to={`/orders/edit/${order.id}`}>Order #{order.refNum}</Link>
            </h5>
            <Badge
              theme={StatusColors[order.status.replace(' ', '_').toUpperCase()]}
              className="ml-2"
            >
              {order.status}
            </Badge>
            {order.deliveryStatus && (
              <Badge
                theme={
                  StatusColors[
                    order.deliveryStatus.replace(' ', '_').toUpperCase()
                  ]
                }
                className="ml-2"
              >
                {order.deliveryStatus}
              </Badge>
            )}
            <span className="ml-auto">
              {!isPreview && isEditable && (
                <Link to={`/orders/edit/${order.id}`}>
                  <Button theme="success" type="button" className="btn-sm">
                    <MaterialIcon className="mr-2" icon="edit" /> Edit
                  </Button>
                </Link>
              )}
            </span>
          </Col>
        </Row>
        {order.payments
          .filter((p) => p.status === 'success')
          .map((p, idx) => (
            <Row key={idx}>
              <Col>{renderPaymentInfo(p)}</Col>
            </Row>
          ))}
      </CardHeader>
      <CardBody>
        <ProductListContainer>
          {order.products.map((product, index) => {
            const promos = getProductPromos(index);
            return (
              <ProductList key={index} className="mb-3">
                <ProductImage src={product.image} alt="" role="presentation" />
                <span className="mx-4">
                  <h6 className="mb-1 font-weight-bold">{product.name}</h6>
                  Variant: <b>{product.variant}</b> <br />
                  Qty: <b>{product.qty}</b> <br />
                  Unit Price: <b>{showProductPrice(product)}</b>
                  {product.subVariants &&
                    product.subVariants.length > 0 &&
                    product.subVariants.map((sub, i) => {
                      if (!sub.name) return;
                      return (
                        <div key={`${sub}_${i}`}>
                          <b>
                            <small>+ {sub.value}</small>
                          </b>
                        </div>
                      );
                    })}
                  {promos.length > 0 && (
                    <span className="d-block mt-2">
                      {promos.map((p, i) => (
                        <PromoBadge key={i} promo={p} className="mr-2" />
                      ))}
                    </span>
                  )}
                </span>
                <b className="ml-auto">
                  {showProductSubtotal(product, promos)}
                </b>
              </ProductList>
            );
          })}

          <div className="border-top d-flex pt-3">
            {getEntireOrderPromos()}
            {showReferralInfo()}
            <div className="ml-auto">
              <table className="table table-borderless table-sm">
                <tbody>
                  <tr>
                    <td>Subtotal:</td>
                    <td width="150px" className="text-right">
                      <b>{formatPrice(order.totalPrice)}</b>
                    </td>
                  </tr>
                  <tr>
                    <td>Discount:</td>
                    <td className="text-right text-danger">
                      <b>{formatPrice(order.discount)}</b>
                    </td>
                  </tr>
                  <tr>
                    <td>Shipping:</td>
                    <td className="text-right">
                      <b>{formatPrice(order.shippingFee)}</b>
                    </td>
                  </tr>
                  <tr>
                    <td>Grand Total:</td>
                    <td className="text-right">
                      <b>{formatPrice(order.finalPrice)}</b>
                    </td>
                  </tr>
                  {renderTaxInfo()}
                </tbody>
              </table>
            </div>
          </div>
        </ProductListContainer>
      </CardBody>
      <CardFooter className="border-top">
        <Row>
          {order.user && (
            <Col>
              <h5>Customer</h5>
              {showUserInfo(order.user)}
            </Col>
          )}
          <Col>
            <h5>Shipping</h5>
            {showAddressInfo(order.shipping)}
          </Col>
          <Col>
            <h5>Billing</h5>
            {showAddressInfo(order.billing)}
          </Col>
        </Row>
      </CardFooter>
    </Card>
  );
};

OrderDetailCard.propTypes = {
  order: PropTypes.object.isRequired,
  isPreview: PropTypes.bool,
};

OrderDetailCard.defaultProps = {
  isPreview: false,
};

export default OrderDetailCard;
